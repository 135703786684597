import React from "react";
import styles from "../../styles.module.css";
import { Row, Container, Button } from "react-bootstrap";

export const ContactUsSection = () => {
  return (
    <Container fluid>
      <Row className={styles.contactRow}>
        <h2>Contact Us</h2>
        <p>We look forward to hearing from you.</p>
        <p>
          Email us at{" "}
          <a
            href="mailto:broker@swmc.com"
            style={{ textDecoration: "underline", color: "#fff" }}
          >
            broker@swmc.com
          </a>
        </p>
        <p>
          Call us at{" "}
          <a
            href="tel:+18004537884"
            style={{ textDecoration: "underline", color: "#fff" }}
          >
            (800) 453-7884
          </a>
        </p>
        <a href="https://www.swmc.com/contact-us" target="_blank" rel='noreferrer'>
          <Button variant="primary" className={styles.contactButton}>
            Contact Us
          </Button>
        </a>

        <hr className={styles.fancyLine} />

        <h2>Frequently Asked Questions</h2>
        <p>Still have questions?</p>

        <p>
          Checkout this{" "}
          <a
            href="https://www.swmc.com/faq-clients"
            style={{ textDecoration: "underline", color: "#fff" }}
          >
            FAQ
          </a>{" "}
          as a resource
        </p>
      </Row>
    </Container>
  );
};

export default ContactUsSection;
