import React from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";

const BlackWrapper = styled.div`
  .form-check-label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    color: black;
    font-size: 14px;
    font-weight: 200;
  }
  .form-check-input[type="radio"] {
    margin-top: 13px !important;
  }
`;
const RadioButton = (props) => {
  let inputOptions;
  if (props.value.length > 0 && props.changed) {
    inputOptions = props.value.map((ele, index) => {
      return (
        <Form.Check
          key={`${props.name}${props.type}`}
          inline
          label={ele}
          type={props.type}
          name={props.name}
          checked={index === props.selected ? true : false}
          value={ele}
          ref={props.register({ required: true })}
          onChange={(ele) => props.changed(ele)}
        />
      );
    });
  } else {
    inputOptions = props.value.map((ele, index) => {
      return (
        <Form.Check
        key={`${props.name}${props.type}`}
          inline
          label={ele}
          type={props.type}
          name={props.name}
          value={ele}
          ref={props.register({ required: true })}
          checked={props.checked}
        />
      );
    });
  }
  return (
    <div style={{ position: "relative" }}>
      {props.color ? (
        <whiteWrapper>{inputOptions}</whiteWrapper>
      ) : (
        <BlackWrapper>{inputOptions}</BlackWrapper>
      )}
      {props.errors[props.name] && <p>{props.errors[props.name].message}</p>}
    </div>
  );
};

export default RadioButton;
