import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledModalHeader = styled(Modal.Header)`
  .close {
    position: absolute;
    right: 1rem;
  }
`;

const modal = props => (
  <Modal show={props.show} size={props.size} onHide={props.onHide} {...props}>
    <StyledModalHeader closeButton>
      {props.title ? (
        <Modal.Title style={{ textAlign: 'center', width: '100%' }}>
          {props.title}
        </Modal.Title>
      ) : null}
      {props.headerContent ? props.headerContent : null}
    </StyledModalHeader>
    {props.children}

    {props.footer ? <Modal.Footer>
      {props.footer ? props.footer : null}
      <Button
        variant="primary"
        onClick={props.onHide}
        style={{ display: 'none' }}
      >
        Close
      </Button>
    </Modal.Footer>
    :null }
  </Modal>
);
export default modal;
