import React from 'react'
import styles from "../../styles.module.css";


export const ProductCard = (props) =>{

    return (
        <a href={props.link} target="_blank" style={{textDecoration: 'none'}} rel='noreferrer'>
        <div className={styles.productCard}>
                <img  alt='' src = {props.src}/>
                <h5>{props.text}</h5>

              </div>
        </a>
    )
}

export default ProductCard