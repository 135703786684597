/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import parse from "html-react-parser";
import styles from "../../styles.module.css";
import { Row, Col, Container } from "react-bootstrap";

export const IntroSection = (props) => {
  const exludeFromURLS = [
    "brokerreverseaffiliate.com",
    "brokerreverseassist.com",
    "brokerreverseassociate.com",
    "broker203kloans.com",
    "broker203krates.com",
    "broker203kinsure.com",
    "broker203klending.com",
    "brokervarefi.com",
    "broker203k.com",
    "brokerrenovationloans.com",
    "brokerusdaassist.com",
    "brokerconventionalgain.com",
    "brokerconventionalassist.com",
    "reversewholesalehub.com",
    "conventionalwholesaleexpert.com",
    "wholesalereversecorner.com",
    "wholesalerenovationloans.com",
    "wholesaleconventionalrefi.com",
    "usdawholesaleexpert.com",
    "brokerconventionalloan.com",
    "wholesaleusdapros.com",
    "wholesaleusdaloan.com",
    "reversewholesaleexpert.com",
    "reversewholesalelending.com",
    "wholesaleconventionallender.com",
    "wholesaleusdalender.com",
    "wholesalehecm.com",
    "wholesalejumboproducts.com",
  ];
  const excludeSection = exludeFromURLS.some((url) =>
    window.location.href.includes(url)
  );

  return (
    <div className={styles.introSection}>
      <img
        alt=""
        src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueBg.svg"
        className={styles.rightbluebg}
      />

      <img
        alt=""
        src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueBg.svg"
        className={styles.leftbluebg}
      />

      <img
        alt=""
        src="https://resources.swmc.com/swmc-images/WholesaleLending/dot4.svg"
        className={styles.leftdot}
      />

      <img
        alt=""
        src="https://resources.swmc.com/swmc-images/WholesaleLending/curves.svg"
        className={styles.rightcurve}
      />
      <Container>
        <Row className={styles.sunWestIntroRow}>
          <h2 className={styles.primaryh2}>Sun West Wholesale</h2>

          <p>
            Sun West Mortgage Company, Inc. is an FHA and VA approved
            full-service mortgage banker in business since 1980. With a fully
            automated, totally integrated, internet-based system to carry out
            all lending functions, SWMC's technology empowers its clients with
            up-to-the-minute status on their loans at anytime through any
            device. SWMC is an approved FHLMC, FNMA**, GNMA, HUD, and VA lender.
            We are also an approved HUD Reverse Mortgage Lender/Servicer. We
            offer a wide range of jumbo and conventional loan products.
            <br />
            <br />
            **Fannie Mae® is a registered trademark of Fannie Mae.
          </p>
        </Row>
        {!excludeSection && (
          <Row>
            <Col className="d-flex justify-content-center">
              <div className={styles.fico}>FICO as low as 500</div>
            </Col>
          </Row>
        )}
        <Row className={styles.dynamicRow}>
          <h2 className={styles.dynamicHeading}>
            {props?.dynamicContent?.heading &&
              parse(props?.dynamicContent?.heading)}
          </h2>
          <div>
            {props?.dynamicContent?.bodyContents &&
              parse(props?.dynamicContent?.bodyContents)}
          </div>
        </Row>

        <Row className={styles.partnerRow}>
          <div className={styles.partnerRectangle}>
            <h2 className={styles.primaryh2}>
              Partner With Sun West Wholesale
            </h2>

            <h3>Choose Your Product to View Rates and Submit Your Loan</h3>

            <Row>
              <Col md={6}>
                <ul>
                  <li>Great Rates and Client Call back Referrals</li>
                  <li>Same or next day approvals</li>
                  <li>Same day condition reviews</li>
                  <li>Private Label Landing pages for your clients</li>
                  <li>Live Loan tracking</li>
                </ul>
              </Col>

              <Col md={6}>
                <ul>
                  <li>Tools and Calculators</li>
                  <li>Individualized branded marketing materials</li>
                  <li>Sponsorship for FHA approval</li>
                  <li>Underwriting, docs, and funding on your behalf</li>
                </ul>
              </Col>
            </Row>
          </div>

          <p>
            Please{" "}
            <a id={styles.clickLink} onClick={props.linkClick}>
              click here
            </a>{" "}
            for a wholesale relationship application <br />
            or email us at{" "}
            <a href="mailto:broker@swmc.com" target="_blank" rel="noreferrer">
              broker@swmc.com
            </a>{" "}
            with any questions.
          </p>
        </Row>
        <img
          alt=""
          src="https://resources.swmc.com/swmc-images/WholesaleLending/eSquareMobile.svg"
          className={styles.eSquare}
        />
        <img
          alt=""
          src="https://resources.swmc.com/swmc-images/WholesaleLending/ellipseMobile.svg"
          className={styles.ellipseMobile}
        />
      </Container>
    </div>
  );
};

export default IntroSection;
