import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";

const StyleWrapper = styled.div`
.consentDesc p{
    color: #000 !important;
width: 650px;
max-width: 100%;
text-align: left;
font-size: 10px !important;
margin-left : 0 !important;
}

.consentDesc p::before{
    display: none !important;
}

.checkboxConsent label{
    line-height: inherit !important;
text-align: left;
display: block;
margin-bottom: 5px;
margin-top: 3px;
font-size: 11px;
font-weight: 700;
margin-left: 20px;
  }

  .checkboxConsent .form-check {
        padding-left: 0 !important;
        margin-top: 15px;
}

.errorMessage{
    color: #bf1650 !important;
    font-size: 14px !important;
}

.form-check{
    padding-left: 0 !important;
        margin-top: 15px !important;
}

.checkboxConsent .form-check-input{
    margin-left: 0 !important;
}

.form-check-input{
    margin-left : 0 !important;
    margin-top : 1px !important;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media(max-width:768px){
     .consentDesc p{
        color: #000 !important;
    width: auto;
    text-align: left;
    font-size: 10px !important;
    }
`;

export const Consent = (props) => {
  let newProps = { ...props };
  delete newProps.handleChange;
  return (
    <StyleWrapper>
      <div className="consentDesc">
        <p className="mt-2 mb-1">
          <strong>Phone Solicitation Consent</strong>
        </p>
        <p className="m-0 text-justify">
          By clicking on I Consent below you agree to Sun West Mortgage Company,
          Inc.'s ("Sun West")
          <a
            href="https://www.swmc.com/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            Security and Privacy Policy
          </a>
          , and you agree to Celligence International, LLC.'s ("Celligence"){" "}
          <a
            href="https://www.swmc.com/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            href="https://www.swmc.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Security and Privacy Policy
          </a>
          , all of which you may access by clicking on the "Terms and
          Conditions" or "Security Policy" web links contained herein.
          {/* Telephonic communications with Sun West and Celligence are recorded
          and monitored. You have the option to request the agent to call you
          back on an unrecorded and unmonitored line. */}
        </p>

        <Form.Check
          label="I CONSENT"
          // name="acceptConsent"
          type="checkbox"
          className="checkboxConsent"
          onChange={props.handleChange}
          id=""
          // name={props.name}
          // ref={props.register}
          {...newProps}
        />

        {/* {props.errors[props.name] && <span className="errorMessage">{props.errors[props.name].message}</span>} */}
      </div>
    </StyleWrapper>
  );
};

export default Consent;
