import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import shuffle from "shuffle-array";
import Classes from "./index.css";

export default function Login(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const instances = ["revmort", "revmort1", "revmort7", "revmort8", "revmort9"];
  const ssInstance = shuffle(instances)[0];

  let path;
  if (props.location) {
    path = props.location.split("/")[1];
  } else path = null;

  const ssURl = `https://${ssInstance}.reversesoftonline.com/ReverseMortgage/jsp/login.jsf`;
  const smlsURL =
    "https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusPostLogin.jsf";

  const resetValues = () => {
    const frm = document.getElementsByName("loginform")[0];

    // Reset all form data
    if (userName !== "" && password !== "") {
      frm.submit();
    } else {
      alert("Enter username/password");
    }

    setTimeout(() => {
      setUserName("");
      setPassword("");
    }, 100);
  };

  return (
    <Container
      className={Classes.loginContainer}
      style={{ width: "100%", marginLeft: "2%" }}
    >
      <Row className="d-flex justify-content-center">
        <Form
          className="text-center"
          method="post"
          id="loginform"
          name="loginform"
          action={path === "sunsoft-login" ? ssURl : smlsURL}
        >
          <Form.Row style={{ marginLeft: "-16px" }} className="mb-3 position-relative">
            <Form.Label srOnly>Username</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img
                    alt=""
                    style={{ width: "24px" }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAABUElEQVRIia3WyypFURzH8Q+RKFGimJBMZKBQBmYuD2Bu4lK8gJlXUAwMvYYyMTAwEQZKiULIZcBAKcllsM4pnc46e2nvX/3au7X2/n33Xpd/izRNYRcPuMAm2hLfTdYCvvFT4RscYLYISA/eq0D++hMjWUH1Gf1LaM54pgHLeUGTWQEljeUF9SeCOvOCvhJB73lBh4mgs7yg7kRQV15QeyIo9xwdJ4L2E5+LakAoObU27JX0Ia6p+QzQShEQaBGKaTXIPVqLAsFcBLSYGpC1GMp6ibSfpoKyNIRVPKr+R89YxzSa/hveIAzJZSQ85hesSZyzUZz/E1DpJ8zUggzjLSek7A9MxEA7BUHK3ouBbgsG3f0Nr4/cF6G6GKiwPSGcmrZinR3Yln3qqeVrbGCwMryuskE49YwLlbsPvaWPaBTqXpOwZ15L1zuc4EhY2lX1C/iXuBtRk3hsAAAAAElFTkSuQmCC"
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                id="userId"
                name={path === "sunsoft-login" ? "UserId" : "userId"}
                placeholder="Username"
                minLength="4"
                maxLength="40"
                required
                value={userName}
                pattern="^[a-zA-Z0-9-.]+$"
                onChange={(e) => setUserName(e.target.value)}
              />
            </InputGroup>
          </Form.Row>
          <Form.Row style={{ marginLeft: "-16px" }} className="mb-3 position-relative">
            <Form.Label srOnly>Password</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img
                    alt=""
                    style={{ width: "24px" }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAABHUlEQVRIic2VQWrCQBSGP0VSEKHg2lD1HAq9Rem2duei10i9hmeQUnAtuHZT6NboQrrSgN0YFxp4iHnjzETxwYPAfPN/mUkyAft6Aj6BGbAGNsfrAdB0yDtbPSAB0pxOgDdfySuwUyRZ74AXV0kd+BNhU+AZqB67C0zE+Ap4dBF9iJBfoHaGqQI/guu7iL5FwLvC9QQ3chEtRUBL4dqCm7uI/kVAoHAPgtvKgdKFotRijg172wo4nAALzN9OXsdAhL7dRB6C0440kc9KTnupPaxUGbOucpFh1xKNgAYQAl8+N2Ha94ZgQxPvs6Ig59q6TCsaC3Zs4n3fumy+kb3ZW1fxnN/hwoPzLj7YRYGeWBMNCxSpWQGHUzemgN/EHtOMriKlrZeFAAAAAElFTkSuQmCC"
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="password"
                id="password"
                name={path === "sunsoft-login" ? "Password" : "password"}
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
          </Form.Row>
          <Form.Row hidden>
            <Form.Group as={Col}>
              <Form.Control
                type="hidden"
                name="autoLogin"
                value="true"
                readOnly
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control
                type="hidden"
                id="loginType"
                name="loginType"
                value="originator"
                readOnly
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control
                type="hidden"
                id="action"
                name="action"
                value="checkLoan"
                readOnly
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          {/* type="submit" */}
          <Button
            style={{ backgroundColor: "#00aae4", borderColor: "#00aae4" }}
            onClick={resetValues}
            type="submit"
          >
            Submit
          </Button>
        </Form>
      </Row>
    </Container>
  );
}
